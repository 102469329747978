import './App.css';
import { useState } from 'react';

import List from './components/List';

import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App() {

  console.log('first', Amplify)

  // const fetchUserGroups = async () => {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const groups = user.signInUserSession.idToken.payload['cognito:groups'];
  //     setUserGroups(groups || []);
  //   } catch (error) {
  //     console.error('Error fetching user groups:', error);
  //   }
  // };



  return (
    <div className="App">
      <div className="auth">
        <Authenticator>
          {({ signOut, user }) => (
              <main>
                <h1>Hello {user?.username}</h1>
                <br/>
                <List />
                <br/>
                <button onClick={signOut}>Sign out</button>
              </main>
          )}
        </Authenticator>
      </div>
    </div>
  );
}

export default App;
