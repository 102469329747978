import React, { useEffect, useState } from 'react'

import { v1 as uuidv1 } from 'uuid';


import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from '@aws-amplify/auth';

import { generateClient } from 'aws-amplify/data';
import { type Schema } from '../../amplify/data/resource';

import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import { listTodos } from '../graphql/queries'
import { createTodo } from '../graphql/mutations'

const client = generateClient<Schema>();
console.log('client', client)

type Todo = {
  id: string;
  name: string;
  description: string;
  updatedByUser: string;
};

type ListTodosResponse = {
  listTodos: {
    items: Todo[];
  };
};

const List: React.FC = () => {

    const [groups, setGroups] = useState<string[]>([])
    const [items, setItems] = useState([])

    const { user } = useAuthenticator((context) => [context.user]);
    const USER = user;

    useEffect(() => {

      const getGroups = async () => {
        const session =  await fetchAuthSession();

        const sessionGroups = session?.tokens?.idToken?.payload['cognito:groups'] || [];
        setGroups(sessionGroups as [])
      }


      const ShowData = async () => {
        const todosData = await client.graphql(graphqlOperation(listTodos, { 
          filter: {
            updatedByUser: {
              eq: user.username
            }
          },
          limit: 1000 
        })) as GraphQLResult<ListTodosResponse>;
        const count = todosData.data.listTodos.items
        setItems(count as any)
      }      
    
      return () => {
        getGroups()
        ShowData()
      }
    }, [])  


    useEffect(() => {
      console.log('INFO', groups, items)
    }, [items, groups])
    
  
    const createToDo = async () => {

      const newId = await uuidv1()

      const newObject = {
        id: newId,
        name: `${USER.username} - new Todo`,
        updatedByUser: USER.username,
        description: 'this a test new Todo'
      }
      const newTodo = await client.graphql(graphqlOperation(createTodo, { input: newObject } ));
      console.log('newTodo', newTodo)
    }

  return (
    <div>
        {groups.includes("ADMINS") && <h2>This User is ADMIN</h2>}
        <br/>
        <button onClick={() => createToDo()}>Create TODO</button>
        <div style={{ marginTop: '50px', color: 'black', lineHeight: '2rem', wordBreak: 'break-word' }}>
          { JSON.stringify(items) }
        </div>
    </div>
  )
}

export default List